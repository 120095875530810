import React from 'react'
import Layout from '../../components/Layout'
import {Location} from '@reach/router'
import Content from '../../components/Content'
import {Link} from 'gatsby'
export default () => (  
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                    <Content>
                        <p>
                        Here at US Alarms we don’t believe in a one size fits all alarm system to protect your home and family. Every house has a unique layout and family lifestyle that requires specific attention to detail to get the correct system and equipment for you.
                        </p>
                        <h2>We have solutions for every situation</h2>
                        <ul>
                        <li>Large Dogs</li>
                        <li>Multiple Cats</li>
                        <li>Florida Rooms</li>
                        <li>Sliding Glass Doors</li>
                        <li>Snow birds/Vacant Houses</li>
                        <li>Kids that leave doors open/unlocked</li>
                        <li>Leaving lights on</li>
                        <li>Forgetting to adjust the thermostat</li>
                        <li>Did you remember to close the garage door?</li>
                        <li>Phone notifications when your kids get home from school</li>
                        </ul>
                        <h2>
                        With the push of one button on your smart phone you can…
                        </h2>
                        <ul>
                        <li>Arm the alarm on stay mode</li>
                        <li>Turn off all the lights</li>
                        <li>Close your garage doors</li>
                        <li>Lock your front and back doors</li>
                        <li>Set your thermostat to 72 degrees</li>
                        </ul>
                        <p>Don’t fall for one of those do it yourself kits or a $99 system that covers next to nothing. Let our professionals custom tailor the system to your needs. <Link to="/free-home-evaluation">Schedule your free home evaluation today.</Link></p>
                    </Content>
                </Layout>
            )
        }
    </Location>
)